import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { ContactPage } from './components/pages/ContactPage';
import { EventsPage } from './components/pages/EventsPage';
import { HomePage } from './components/pages/HomePage';
import { MenuPage } from './components/pages/MenuPage';

function App() {
  const router = createBrowserRouter([
    {
      id: 'app',
      children: [
        {
          id: 'home',
          path: '/',
          element: <HomePage />,
        },
        {
          id: 'events',
          path: '/eventos',
          element: <EventsPage />,
        },
        {
          id: 'contact',
          path: '/contacto',
          element: <ContactPage />,
        },
        {
          id: 'menu',
          children: [
            {
              id: 'zibata',
              path: '/menu/zibata',
              element: <MenuPage />,
            },
            {
              id: 'marques',
              path: '/menu/lomas-del-marques',
              element: <MenuPage />,
            },
          ],
        },
        {
          id: '404',
          path: '/*',
          element: <span>404</span>,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
