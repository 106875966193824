import { Link } from 'react-router-dom';
import { useStyles } from '../hooks/useStyles';
import { Base } from '../layouts/Base';
import { ConstrainedBox } from '../ui/ConstrainedBox';

export function ContactPage() {
  const { css, linkClass, mediaQueries } = useStyles();
  return (
    <Base small>
      <ConstrainedBox
        className={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '26px',
          color: 'white',
          fontWeight: 'bold',
          gap: '13px',
          [mediaQueries.xl]: {
            gap: '25px',
          },
        })}
      >
        <p>
          Llama al:{' '}
          <Link to="tel:4422290646" rel="noopener" className={linkClass}>
            4422290646
          </Link>
          <br />
          Whatsapp:
          <Link
            to="https://api.whatsapp.com/send?phone=+524428527742&amp;text=Hola!%20Estoy%20interesado%20en%20un%20pedido."
            target="_blank"
            rel="noopener"
            className={linkClass}
          >
            4428527742
          </Link>
          <br />
          Rappi:{' '}
          <Link
            to="https://www.rappi.com.mx/restaurantes/el-lonche-del-capitan"
            target="_blank"
            rel="noopener"
            className={linkClass}
          >
            El Lonche del Capitán
          </Link>
          <br />
          <br />
          <Link
            to="mailto:ellonchedelcapitan@gmail.com?Subject=Hola!%20Estoy%20interesado%20en%20un%20evento."
            rel="noopener"
            className={linkClass}
          >
            ellonchedelcapitan@gmail.com
          </Link>
        </p>

        <p>
          <Link to="https://goo.gl/maps/Ej1g4J2JnWEmf9xg7" target="_blank" rel="noopener" className={linkClass}>
            Av. Paseo de la Reforma #506
            <br />
            Col: Lomas del Marqués 76146 Querétaro, México.
          </Link>
        </p>
        <p>
          <Link to="https://goo.gl/maps/2K4BvPFfLztZH2bPA" target="_blank" rel="noopener" className={linkClass}>
            Paseo de las Pitahayas 1 (Xentric Anáhuac)
            <br />
            Col: Zibatá 76269 Querétaro, México.
          </Link>
        </p>
      </ConstrainedBox>
    </Base>
  );
}
