import { useMatches } from 'react-router-dom';
import { MenuBase } from '../layouts/MenuBase';

import marques1 from '../../assets/img/marques/m1.jpg';
import marques10 from '../../assets/img/marques/m10.jpg';
import marques11 from '../../assets/img/marques/m11.jpg';
import marques12 from '../../assets/img/marques/m12.jpg';
import marques2 from '../../assets/img/marques/m2.jpg';
import marques3 from '../../assets/img/marques/m3.jpg';
import marques4 from '../../assets/img/marques/m4.jpg';
import marques5 from '../../assets/img/marques/m5.jpg';
import marques6 from '../../assets/img/marques/m6.jpg';
import marques7 from '../../assets/img/marques/m7.jpg';
import marques8 from '../../assets/img/marques/m8.jpg';
import marques9 from '../../assets/img/marques/m9.jpg';

import zibata1 from '../../assets/img/zibata/z1.jpg';
import zibata10 from '../../assets/img/zibata/z10.jpg';
import zibata11 from '../../assets/img/zibata/z11.jpg';
import zibata12 from '../../assets/img/zibata/z12.jpg';
import zibata13 from '../../assets/img/zibata/z13.jpg';
import zibata14 from '../../assets/img/zibata/z14.jpg';
import zibata15 from '../../assets/img/zibata/z15.jpg';
import zibata2 from '../../assets/img/zibata/z2.jpg';
import zibata3 from '../../assets/img/zibata/z3.jpg';
import zibata4 from '../../assets/img/zibata/z4.jpg';
import zibata5 from '../../assets/img/zibata/z5.jpg';
import zibata6 from '../../assets/img/zibata/z6.jpg';
import zibata7 from '../../assets/img/zibata/z7.jpg';
import zibata8 from '../../assets/img/zibata/z8.jpg';
import zibata9 from '../../assets/img/zibata/z9.jpg';
import { useStyles } from '../hooks/useStyles';

export function MenuPage() {
  const { imgResponsive } = useStyles();
  const zibataImages = [
    zibata1,
    zibata2,
    zibata3,
    zibata4,
    zibata5,
    zibata6,
    zibata7,
    zibata8,
    zibata9,
    zibata10,
    zibata11,
    zibata12,
    zibata13,
    zibata14,
    zibata15,
  ];
  const marquesImages = [
    marques1,
    marques2,
    marques3,
    marques4,
    marques5,
    marques6,
    marques7,
    marques8,
    marques9,
    marques10,
    marques11,
    marques12,
  ];

  const matches = useMatches();
  const matchId = matches[matches.length - 1].id;
  const place = matchId === 'zibata' ? 'ZIBATÁ' : 'LOMAS DEL MARQUÉS';

  return (
    <MenuBase placeName={place}>
      {matchId === 'zibata'
        ? zibataImages.map((imageUrl, index) => <img src={imageUrl} className={imgResponsive} key={index} alt="" />)
        : marquesImages.map((imageUrl, index) => <img src={imageUrl} className={imgResponsive} key={index} alt="" />)}
    </MenuBase>
  );
}
