import { Base } from '../layouts/Base';
import { Navigation } from '../shared/Navigation';

export function HomePage() {
  return (
    <Base>
      <Navigation />
    </Base>
  );
}
