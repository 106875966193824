import { Link } from 'react-router-dom';
import { useStyles } from '../hooks/useStyles';
import { ConstrainedBox } from '../ui/ConstrainedBox';

import facebook from '../../assets/img/social/fb.png';
import instagram from '../../assets/img/social/ig.png';
import redes from '../../assets/img/social/redes.png';

export function Social() {
  const { css, imgResponsive, imgSocial, concatenateClasses } = useStyles();

  const imageClasses = concatenateClasses(imgResponsive, imgSocial);

  return (
    <ConstrainedBox
      className={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
      })}
    >
      <Link to="https://www.facebook.com/ellonchedelcapitan" target="_blank" rel="noopener">
        <img src={facebook} alt="" className={imageClasses} />
      </Link>
      <Link to="https://www.instagram.com/ellonchedelcapitan" target="_blank" rel="noopener">
        <img src={instagram} alt="" className={imageClasses} />
      </Link>
      <img src={redes} alt="" className={imageClasses} />
    </ConstrainedBox>
  );
}
