import { Link } from 'react-router-dom';
import { useBackgroundColor } from '../hooks/useBackgroundColor';
import { useBackgroundImage } from '../hooks/useBackgroundImage';
import { useStyles } from '../hooks/useStyles';
import { HeadTitle } from '../shared/HeadTitle';
import { WhatsApp } from '../shared/WhatsApp';
import { ConstrainedBox } from '../ui/ConstrainedBox';
import { FullContainer } from '../ui/FullContainer';

import logo from '../../assets/img/logo.png';

type Props = {
  children: React.ReactNode;
  placeName: string;
};

export function MenuBase({ children, placeName }: Props) {
  const { flexColumn, mediaQueries, css, imgResponsive, concatenateClasses } = useStyles();
  const backgroundImage = useBackgroundImage();
  const backgroundColor = useBackgroundColor();

  return (
    <>
      <HeadTitle />
      <WhatsApp />
      <FullContainer>
        <div
          className={css({
            backgroundImage: `url('${backgroundImage}')`,
            backgroundPosition: 'center 40%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            height: '250px',
            [mediaQueries.xl]: {
              height: '350px',
            },
          })}
        >
          <ConstrainedBox
            className={css({
              position: 'absolute',
              top: '20px',
              right: 0,
              left: 0,
              [mediaQueries.xl]: {
                top: '40px',
              },
            })}
          >
            <Link
              to="/"
              className={concatenateClasses(
                css({
                  color: 'white',
                  textDecoration: 'none',
                  fontSize: '35px',
                  [mediaQueries.xl]: { fontSize: '45px' },
                })
              )}
            >
              menú
            </Link>
          </ConstrainedBox>
          <ConstrainedBox styles={{ alignItems: 'center', marginBottom: '30px' }} className={flexColumn}>
            <img
              src={logo}
              alt=""
              className={concatenateClasses(
                imgResponsive,
                css({ height: '140px', [mediaQueries.xl]: { height: '200px' } })
              )}
            />
          </ConstrainedBox>
          <ConstrainedBox
            className={css({
              position: 'absolute',
              bottom: '20px',
              right: 0,
              left: 0,
              [mediaQueries.xl]: {
                bottom: '40px',
              },
              textAlign: 'right',
            })}
          >
            <span
              className={concatenateClasses(
                css({ color: 'white', fontSize: '30px', [mediaQueries.xl]: { fontSize: '45px' } })
              )}
            >
              {placeName}
            </span>
          </ConstrainedBox>
        </div>
        <div
          className={concatenateClasses(
            flexColumn,
            css({
              alignItems: 'center',
              position: 'relative',
              backgroundColor: backgroundColor,
              minHeight: 'calc(100vh - 250px)',
              [mediaQueries.xl]: { minHeight: 'calc(100vh - 350px)' },
            })
          )}
        >
          {children}
        </div>
      </FullContainer>
    </>
  );
}
