import { Link } from 'react-router-dom';
import { useStyles } from '../hooks/useStyles';
import { Base } from '../layouts/Base';
import { ConstrainedBox } from '../ui/ConstrainedBox';

import reuniones from '../../assets/img/reuniones.png';

export function EventsPage() {
  const { css, linkClass, imgResponsive, mediaQueries, concatenateClasses } = useStyles();
  return (
    <Base small>
      <ConstrainedBox
        className={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '26px',
          color: 'white',
          fontWeight: 'bold',
          gap: '16px',
        })}
      >
        <img
          src={reuniones}
          alt=""
          className={concatenateClasses(
            imgResponsive,
            css({
              height: '40px',
              [mediaQueries.md]: {
                height: '45px',
              },
              [mediaQueries.lg]: {
                height: '50px',
              },
              [mediaQueries.xl]: {
                height: '60px',
              },
            })
          )}
        />
        <p>Preparamos en tu domicilio lo que gustes:</p>
        <p>
          Mariscadas
          <br />
          Pescado zarandeado
          <br />
          Tacos
          <br />
          Ceviches
          <br />
          <br />
          Lo que quieras hasta tu casa...
        </p>
        <p>
          ¡Mándanos un correo a{' '}
          <Link
            to="mailto:ellonchedelcapitan@gmail.com?Subject=Hola!%20Estoy%20interesado%20en%20un%20evento."
            className={linkClass}
            rel="noopener"
          >
            ellonchedelcapitan@gmail.com
          </Link>{' '}
          o un whatsapp a{' '}
          <Link
            to="https://api.whatsapp.com/send?phone=+525522992012&amp;text=Hola!%20Estoy%20interesado%20en%20un%20evento."
            className={linkClass}
            target="_blank"
            rel="noopener"
          >
            5522992012
          </Link>{' '}
          y te armamos un paquete a tus necesidades!
        </p>
      </ConstrainedBox>
    </Base>
  );
}
