import { Link } from 'react-router-dom';
import { useStyles } from '../hooks/useStyles';
import { ConstrainedBox } from '../ui/ConstrainedBox';

import eventsButton from '../../assets/img/links/eventos.png';
import lomasButton from '../../assets/img/links/menul.png';
import zibataButton from '../../assets/img/links/menuz.png';
import contactButton from '../../assets/img/links/redes.png';

export function Navigation() {
  const { breakpoints, imgResponsive, css, concatenateClasses } = useStyles();

  const imgClass = css({ height: '50px', objectFit: 'contain' });
  const imageClasses = concatenateClasses(imgResponsive, imgClass);

  return (
    <ConstrainedBox
      className={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '20px',
        columnGap: '30px',
        [`@media (min-width: ${breakpoints.md})`]: {
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
      })}
    >
      <Link to="/menu/lomas-del-marques">
        <img src={lomasButton} alt="" className={imageClasses} />
      </Link>
      <Link to="/menu/zibata">
        <img src={zibataButton} alt="" className={imageClasses} />
      </Link>
      <Link to="/eventos">
        <img src={eventsButton} alt="" className={imageClasses} />
      </Link>
      <Link to="/contacto">
        <img src={contactButton} alt="" className={imageClasses} />
      </Link>
    </ConstrainedBox>
  );
}
