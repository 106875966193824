import { Link } from 'react-router-dom';

import whatsappIcon from '../../assets/img/wa.png';
import { useStyles } from '../hooks/useStyles';

export function WhatsApp() {
  const { css, mediaQueries } = useStyles();

  return (
    <div
      className={css({
        position: 'fixed',
        right: '5%',
        top: '20px',
        zIndex: 1,
        display: 'block',
        [mediaQueries.md]: {
          display: 'none',
        },
      })}
    >
      <Link to="https://api.whatsapp.com/send?phone=+524428527742&amp;text=Hola!" target="_blank" rel="noopener">
        <img src={whatsappIcon} alt="" />
      </Link>
    </div>
  );
}
