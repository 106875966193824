export function ConstrainedBox({
  children,
  styles,
  className,
}: {
  children: React.ReactNode;
  styles?: React.CSSProperties;
  className?: string;
}) {
  return (
    <div style={{ maxWidth: '1408px', margin: '0 auto', padding: '0 5%', ...styles }} className={className}>
      {children}
    </div>
  );
}
