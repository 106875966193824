import { useStyletron } from 'styletron-react';

export function useStyles() {
  const [css] = useStyletron();
  const accentColor = '#1ED1F0';

  const breakpoints = {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  };

  const mediaQueries = {
    xs: `@media screen and (min-width: ${breakpoints.xs})`,
    sm: `@media screen and (min-width: ${breakpoints.sm})`,
    md: `@media screen and (min-width: ${breakpoints.md})`,
    lg: `@media screen and (min-width: ${breakpoints.lg})`,
    xl: `@media screen and (min-width: ${breakpoints.xl})`,
    xxl: `@media screen and (min-width: ${breakpoints.xxl})`,
  };

  const imgResponsive = css({
    maxWidth: '100%',
  });

  const imgSocial = css({
    objectFit: 'contain',
    maxHeight: '38px',
  });

  const flexColumn = css({
    display: 'flex',
    flexDirection: 'column',
  });

  const flexRow = css({
    display: 'flex',
  });

  const concatenateClasses = (...classes: string[]) => {
    return classes.join(' ');
  };

  const linkClass = css({
    color: accentColor,
    textDecoration: 'none',
  });

  return {
    breakpoints,
    imgResponsive,
    flexColumn,
    flexRow,
    css,
    concatenateClasses,
    imgSocial,
    mediaQueries,
    linkClass,
  };
}
