export function FullContainer({
  children,
  styles,
  className,
}: {
  children: React.ReactNode;
  styles?: React.CSSProperties;
  className?: string;
}) {
  return (
    <div style={{ minHeight: '100vh', width: '100%', boxSizing: 'border-box', ...styles }} className={className}>
      {children}
    </div>
  );
}
