import { Link, useMatches } from 'react-router-dom';
import { useBackgroundImage } from '../hooks/useBackgroundImage';
import { useStyles } from '../hooks/useStyles';
import { HeadTitle } from '../shared/HeadTitle';
import { Social } from '../shared/Social';
import { WhatsApp } from '../shared/WhatsApp';
import { ConstrainedBox } from '../ui/ConstrainedBox';
import { FullContainer } from '../ui/FullContainer';

import logo from '../../assets/img/logo.png';

type Props = {
  children: React.ReactNode;
  small?: boolean;
};

export function Base({ children, small }: Props) {
  const { flexColumn, mediaQueries, css, imgResponsive, concatenateClasses } = useStyles();
  const backgroundImage = useBackgroundImage();
  const matches = useMatches();

  const pageId = matches[matches.length - 1].id;

  return (
    <>
      <HeadTitle />
      <WhatsApp />
      <FullContainer
        styles={{
          backgroundImage: `url('${backgroundImage}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <FullContainer
          styles={{ overflowY: 'auto', paddingTop: '80px', paddingBottom: '40px', position: 'relative' }}
          className={concatenateClasses(
            flexColumn,
            css({
              gap: small ? '40px' : '60px',
              [mediaQueries.xl]: {
                gap: small ? '80px' : '100px',
              },
            })
          )}
        >
          {pageId !== 'home' && (
            <ConstrainedBox
              className={css({
                position: 'absolute',
                top: '20px',
                right: 0,
                left: 0,
                [mediaQueries.xl]: {
                  top: '40px',
                },
              })}
            >
              <Link
                to="/"
                className={concatenateClasses(
                  css({
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: '35px',
                    [mediaQueries.xl]: { fontSize: '45px' },
                  })
                )}
              >
                menú
              </Link>
            </ConstrainedBox>
          )}
          <ConstrainedBox styles={{ alignItems: 'center' }} className={flexColumn}>
            <img
              src={logo}
              alt=""
              className={concatenateClasses(imgResponsive, small ? css({ maxWidth: '80%' }) : '')}
            />
          </ConstrainedBox>
          {children}
          <Social />
        </FullContainer>
      </FullContainer>
    </>
  );
}
